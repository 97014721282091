<script>
  export let dark;
</script>

<div class="tickboxes" class:dark>
  <div class="day">
    <div class="letter">M</div>
    <div class="box" />
  </div>
  <div class="day">
    <div class="letter">T</div>
    <div class="box" />
  </div>
  <div class="day">
    <div class="letter">W</div>
    <div class="box" />
  </div>
  <div class="day">
    <div class="letter">T</div>
    <div class="box" />
  </div>
  <div class="day">
    <div class="letter">F</div>
    <div class="box" />
  </div>
  <div class="day">
    <div class="letter">S</div>
    <div class="box" />
  </div>
  <div class="day">
    <div class="letter">S</div>
    <div class="box" />
  </div>
</div>

<style>
  .tickboxes {
    display: flex;
    font-style: normal;
    font-weight: bold;
    font-family: sans-serif;
    font-size: 0.7em;
    justify-content: space-evenly;
    max-width: 100px;
    margin: 0 auto;
    padding: 0.1em 0.4em;
  }
  .tickboxes .day {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: rgba(255, 255, 255, 0.9);
  }
  .tickboxes .box {
    border: 1px solid rgba(255, 255, 255, 0.9);
    background: rgba(255, 255, 255, 0.5);
    height: 0.7em;
    width: 0.7em;
  }
  .tickboxes.dark .day {
    color: rgba(0, 0, 0, 0.6);
  }
  .tickboxes.dark .box {
    border: 1px solid rgba(0, 0, 0, 0.5);
    background: rgba(255, 255, 255, 0.5);
  }
</style>
