<script>
  import { flip } from "svelte/animate";
  import { dndzone } from "svelte-dnd-action";

  export let items = [];
  export let onSort = () => {};
  export let dragDisabled = false;

  function handle(e) {
    onSort(e.detail.items);
  }

  // selects reset when cloned..
  // Ugly hack to infer selected values from data...
  function transformDraggedElement(el, data, index) {
    let selects = Array.from(el.querySelectorAll("select"));

    for (let select of selects) {
      for (let option of select.options) {
        if (data.size === option.value || data.frequency === option.value) {
          option.setAttribute("selected", "selected");
        }
      }
    }
  }

  const flipDurationMs = 100;
</script>

<div
  use:dndzone={{ items, flipDurationMs, dragDisabled, transformDraggedElement }}
  on:consider={handle}
  on:finalize={handle}
>
  {#each items as item, index (item.id)}
    <div class="sortable-item" animate:flip={{ duration: flipDurationMs }}>
      <div class="handle">↕</div>
      <slot {item} {index} />
    </div>
  {/each}
</div>

<style>
  .sortable-item {
    display: flex;
  }
  :global(.handle),
  :global(.delete) {
    font-size: 21px;
    display: flex;
    align-items: center;
  }
  :global(.delete) {
    cursor: pointer;
  }
  :global(.handle) {
    cursor: grab;
  }
</style>
