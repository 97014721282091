<script>
  import { onMount } from "svelte";
  import { format, set, isDate, isValid } from "date-fns";
  import Autocomplete from "simple-svelte-autocomplete";
  import { HsvPicker } from "svelte-color-picker";
  import Sortable from "./Sortable.svelte";
  import { DateInput } from "date-picker-svelte";
  import Editable from "./Editable.svelte";
  import Tickboxes from "./Tickboxes.svelte";

  import {
    RGBToHex,
    freqToHours,
    to12Hr,
    freqToText,
    textToFreq,
    textColor,
  } from "./utils.js";

  import createPDF from "./createPdf.js";

  import drugs from "./drugs.js";

  let animalName = "";
  let clientName = "";
  let date = new Date();
  let nextAppt = false;
  let nextApptDate = set(date, { hours: 1, minutes: 0 });
  let nextApptType = isDate(nextApptDate) ? "date" : "text";
  let tickBoxes;
  let textSize = 14;
  let hours = [
    5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24,
    1, 2, 3, 4,
  ];
  let hourStart = 7;
  let hourEnd = 24;
  let zoom = 0.75;
  let selectedDrugs = [];
  let newDrug;

  onMount(() => {
    let toNum = (s) => Number(s.replace(/px$/, ""));
    let homesheetWidth = document.getElementById("homesheet").offsetWidth;
    let container = document.getElementById("output");
    let containerWidth = container.offsetWidth;
    let { paddingLeft, paddingRight } = getComputedStyle(container);
    containerWidth = containerWidth - toNum(paddingLeft) - toNum(paddingRight);

    zoom = containerWidth / homesheetWidth;
  });

  $: selectedEyeDrugs = selectedDrugs.filter(
    (drug) => drug.formulation === "eyeTopical"
  );
  $: selectedOtherDrugs = selectedDrugs.filter(
    (drug) => drug.formulation !== "eyeTopical"
  );
  $: tableColumns = [
    { id: "hr" },
    ...selectedEyeDrugs,
    ...(selectedEyeDrugs.length && selectedOtherDrugs.length
      ? [{ id: "gap" }]
      : []),
    ...selectedOtherDrugs,
  ];

  $: visibleHours = hours.filter(
    (hour, i) => i >= hours.indexOf(hourStart) && i <= hours.indexOf(hourEnd)
  );

  if (location.hash) {
    let _state = JSON.parse(atob(location.hash.replace("#", "")));
    animalName = _state.animalName;
    clientName = _state.clientName;
    selectedDrugs = _state.selectedDrugs;
    tickBoxes = _state.tickBoxes;
    nextAppt = _state.nextAppt;
    nextApptDate = isValid(new Date(_state.nextApptDate))
      ? new Date(_state.nextApptDate)
      : _state.nextApptDate;
    nextApptType = isDate(nextApptDate) ? "date" : "text";
    hourStart = _state.hourStart;
    hourEnd = _state.hourEnd;
    textSize = _state.textSize;
  }

  $: state = JSON.stringify({
    animalName,
    clientName,
    selectedDrugs,
    tickBoxes,
    nextAppt,
    nextApptDate,
    hourStart,
    hourEnd,
    textSize,
  });
  $: history.replaceState(
    null,
    null,
    document.location.pathname + "#" + btoa(state)
  );

  function removeDrug(i) {
    selectedDrugs = [
      ...selectedDrugs.slice(0, i),
      ...selectedDrugs.slice(i + 1),
    ];
  }

  $: if (newDrug) {
    let clone = JSON.parse(JSON.stringify(newDrug));
    clone.id = Math.floor(Math.random() * 100000000);
    clone.hours = freqToHours(clone.frequency);
    clone.hoursText = {};

    selectedDrugs.push(clone);
    selectedDrugs = selectedDrugs;
    newDrug = undefined;
  }

  $: planWidth =
    selectedDrugs.length === 1
      ? "small"
      : selectedDrugs.length === 2
      ? "medium"
      : selectedDrugs.length === 3
      ? "large"
      : "full";

  $: planPct = {
    small: 50,
    medium: 70,
    large: 85,
    full: 100,
  }[planWidth];

  $: gapWidth = 85 - 3.5 * selectedDrugs.length;

  $: title =
    date && animalName && clientName
      ? "Home Sheet (" +
        format(date, "yyyy-MM-dd") +
        ") " +
        animalName +
        " " +
        clientName
      : "Home Sheet Editor";

  $: pdfParams = {
    textSize,
    gapWidth,
    tableColumns,
    planPct,
    animalName,
    clientName,
    selectedDrugs,
    tickBoxes,
    nextAppt,
    nextApptDate,
    visibleHours,
    title,
    date,
  };

  // let updateIframe = debounce(() => {
  //   createPDF(pdfParams).getDataUrl((dataUrl) => {
  //     let iframe = document.getElementById("pdfiframe");
  //     iframe.src = dataUrl;
  //   });
  // }, 300);

  // let mounted = false;
  // onMount(() => (mounted = true));

  // $: if (mounted && state) updateIframe();

  function download() {
    createPDF(pdfParams).download(title);
  }
  function print() {
    createPDF(pdfParams).print();
  }
  function clear() {
    if (!confirm("Are you sure you wish to clear the sheet?")) return;
    location.hash = "";
    location.reload();
  }
</script>

<svelte:head>
  <title>{title}</title>
</svelte:head>

<div id="content">
  <div id="sidebar">
    <div class="field">
      <label>Animal name:</label>
      <input type="text" bind:value={animalName} autofocus />
    </div>
    <div class="field">
      <label>Client name:</label>
      <input type="text" bind:value={clientName} />
    </div>

    <div class="field">
      <label for="">Date:</label>
      <DateInput bind:value={date} format="yyyy-MM-dd" />
    </div>

    <div class="field">
      <label>Treatment Items:</label>
      <Sortable
        items={selectedDrugs}
        onSort={(sorted) => {
          selectedDrugs = sorted;
        }}
        dragDisabled={selectedDrugs.find((item) => item._showPicker)}
        let:index
      >
        <div
          class="drug-row"
          class:long={selectedDrugs[index].formulation !== "eyeTopical" &&
            !selectedDrugs[index].sizes}
        >
          <Autocomplete
            items={drugs}
            selectedItem={selectedDrugs[index]}
            onChange={(item) => {
              if (item === selectedDrugs[index]) return;
              let clone = JSON.parse(JSON.stringify(item));
              clone.id = Math.floor(Math.random() * 100000000);
              clone.hours = freqToHours(clone.frequency);

              selectedDrugs[index] = clone;
            }}
            itemSortFunction={(a, b, search) => {
              let rgx = new RegExp("^" + search, "i");

              if (a.name.match(rgx)) {
                if (!b.name.match(rgx)) return -1;
                if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                if (b.name.toLowerCase() > a.name.toLowerCase()) return -1;
              }
              if (b.name.match(rgx)) return 1;
              return 0;
            }}
            labelFieldName="name"
          />
          {#if selectedDrugs[index].sizes}
            <select bind:value={selectedDrugs[index].size} class="drug-size">
              {#each selectedDrugs[index].sizes as size}
                <option value={size}>{size}</option>
              {/each}
            </select>
          {/if}
          {#if selectedDrugs[index].formulation === "eyeTopical"}
            <select bind:value={selectedDrugs[index].eye} class="drug-eye">
              <option value="___ eye(s)">___ eye(s)</option>
              <option value="Both eyes">Both eyes</option>
              <option value="Left eye">Left eye</option>
              <option value="Right eye">Right eye</option>
            </select>
          {/if}
          {#if selectedDrugs[index].dose != null}
            <input
              type="text"
              bind:value={selectedDrugs[index].dose}
              class="drug-dose"
            />
          {/if}
          <select
            class="drug-freq"
            bind:value={selectedDrugs[index].frequency}
            on:change={() => {
              selectedDrugs[index].hours = freqToHours(
                selectedDrugs[index].frequency
              );
            }}
          >
            <option value={null} />
            <option value="sid">sid</option>
            <option value="bid">bid</option>
            <option value="tid">tid</option>
            <option value="qid">qid</option>
          </select>
          <div
            class="colorpicker-button"
            on:click={() => {
              selectedDrugs[index]._showPicker = true;
            }}
          >
            <div
              class="colorpicker-button-color"
              style="background: {selectedDrugs[index].color};"
            />
          </div>
          {#if selectedDrugs[index]._showPicker}
            <div
              class="colorpicker-popover"
              on:click={() => {
                selectedDrugs[index]._showPicker = false;
              }}
            />
            <div class="colorpicker">
              <HsvPicker
                on:colorChange={(rgba) => {
                  selectedDrugs[index].color = RGBToHex(rgba.detail);
                }}
                startColor={selectedDrugs[index].color}
              />
            </div>
          {/if}
          <div class="delete" on:click={() => removeDrug(index)}>×</div>
        </div>
      </Sortable>

      <div style="display:flex;align-items:stretch;" class="long">
        <div
          class="reorder"
          style="color:#999;cursor:default;margin-right:3px;"
        >
          ↕
        </div>
        <Autocomplete
          items={drugs}
          bind:selectedItem={newDrug}
          labelFieldName="name"
          placeholder="Search treatment items..."
          itemSortFunction={(a, b, search) => {
            let rgx = new RegExp("^" + search, "i");

            if (a.name.match(rgx)) {
              if (!b.name.match(rgx)) return -1;
              if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
              if (b.name.toLowerCase() > a.name.toLowerCase()) return -1;
            }
            if (b.name.match(rgx)) return 1;
            return 0;
          }}
        />
      </div>
    </div>
    <div class="field">
      <div>
        <label
          >Display tick Boxes: <input
            type="checkbox"
            bind:checked={tickBoxes}
          /></label
        >
      </div>
    </div>
    <div class="field">
      <div>
        <label>Hours:</label>
        <select bind:value={hourStart}>
          {#each hours as hour}
            <option value={hour}>{to12Hr(hour)}</option>
          {/each}
        </select>
        to
        <select bind:value={hourEnd}>
          {#each hours as hour}
            <option value={hour}>{to12Hr(hour)}</option>
          {/each}
        </select>
      </div>
    </div>
    <div class="field">
      <label
        >Next appointment:
        <input type="checkbox" bind:checked={nextAppt} /></label
      >
      {#if nextAppt}
        <div style="display:flex">
          <select
            bind:value={nextApptType}
            on:change={(e) => {
              if (nextApptType === "date" && !isDate(nextApptDate))
                nextApptDate = new Date();
            }}
          >
            <option value="date">Date</option>
            <option value="text">Text</option>
          </select>
          &nbsp;
          {#if nextApptType === "date" && isDate(nextApptDate)}
            <DateInput
              max={new Date(2099, 12, 31, 0, 0)}
              bind:value={nextApptDate}
              format="yyyy-MM-dd"
            />
            &nbsp;
            <input
              type="time"
              value={format(nextApptDate, "HH:mm")}
              on:input={(e) => {
                nextApptDate = set(nextApptDate, {
                  hours: e.target.value.substr(0, 2),
                  minutes: e.target.value.substr(3, 2),
                });
              }}
            />
          {:else}
            <input type="text" bind:value={nextApptDate} />
          {/if}
        </div>
      {/if}
    </div>
    <div class="field">
      <div>
        <label>Text Size:</label>
        <div style="display:flex;align-items;center">
          <input
            type="range"
            min="6"
            max="20"
            step="0.5"
            bind:value={textSize}
          />
          <span
            >{Number.isInteger(textSize) ? textSize + ".0" : textSize}px</span
          >
        </div>
      </div>
    </div>
    <hr />
    <div class="field">
      <button style="cursor:pointer" on:click={download}> Download </button>
      <button style="cursor:pointer" on:click={print}> Print </button>
      <button style="cursor:pointer;float:right;" on:click={clear}>
        Clear Sheet
      </button>
    </div>
  </div>
  <div id="controls">
    Zoom ({Math.floor(zoom * 100)}%):
    <input type="range" min="0.2" max="2" step="0.01" bind:value={zoom} />
  </div>
  <div id="output">
    <div id="homesheet-dimensions" style="zoom:{zoom};" />
    <div id="homesheet" style="transform:scale({zoom})">
      <div class="heading-name">
        Name: {animalName}&nbsp;{clientName}
      </div>
      <div class="heading-date">Date: {format(date, "EEEE, do MMMM yyyy")}</div>
      {#if selectedDrugs.length > 1}
        <div class="eye-meds-info">
          Please allow five minutes between eye medications
        </div>
      {/if}
      {#if selectedDrugs.length}
        <table
          class="treatment-plan"
          style="font-size:{textSize}px;width:{planPct}%"
        >
          <thead>
            <tr>
              {#each tableColumns as item (item.id)}
                {#if item.id === "hr"}
                  <th class="hour" style="width:{gapWidth}px" />
                {:else if item.id === "gap"}
                  <th class="gap" style="width:{gapWidth}px" />
                {:else}
                  <th
                    style="background:{item.color};color:{textColor(
                      item.color
                    )}"
                  >
                    <div>
                      <b>
                        <Editable
                          value={item.name + (item.size ? " " + item.size : "")}
                          on:change={(e) => {
                            if (item.sizes) {
                              let bits = e.detail.split(" ");
                              if (bits.length > 1) item.size = bits.pop();
                              item.name = bits.join(" ");
                            } else {
                              item.name = e.detail;
                            }
                            selectedDrugs = selectedDrugs;
                          }}
                        />
                      </b>
                    </div>
                    {#if item.use}
                      <div style="font-size:0.6em;line-height:0.8em">
                        (<Editable bind:value={item.use} />)
                      </div>
                    {/if}
                    {#if item.eye}
                      <div>
                        <i><Editable bind:value={item.eye} /></i>
                      </div>
                    {/if}
                    {#if item.note}
                      <div>
                        <i><Editable bind:value={item.note} /></i>
                      </div>
                    {/if}
                  </th>
                {/if}
              {/each}
            </tr>
            <tr>
              {#each tableColumns as item (item.id)}
                {#if item.id === "hr"}
                  <th class="hour" style="width:{gapWidth}px" />
                {:else if item.id === "gap"}
                  <th class="gap" style="width:{gapWidth}px" />
                {:else}
                  <th
                    style="background:{item.color};color:{textColor(
                      item.color
                    )}"
                  >
                    {#if item.dose}
                      <div>
                        <Editable bind:value={item.dose} />
                      </div>
                    {/if}
                    {#if item.frequency}
                      <div>
                        <Editable
                          value={freqToText(item.frequency)}
                          on:change={(e) => {
                            item.frequency = textToFreq(e.detail);
                            selectedDrugs = selectedDrugs;
                          }}
                        />
                      </div>
                    {/if}
                  </th>
                {/if}
              {/each}
            </tr>
          </thead>
          <tbody>
            {#each visibleHours as hour}
              <tr>
                {#each tableColumns as item (item.id)}
                  {#if item.id === "hr"}
                    <th class="hour" style="width:{gapWidth}px"
                      >{to12Hr(hour)}</th
                    >
                  {:else if item.id === "gap"}
                    <td class="gap" style="width:{gapWidth}px" />
                  {:else if item.hours.indexOf(hour) > -1}
                    <td
                      style="text-align:center;font-style:italic;background-color:{item.color};"
                      on:dblclick={() =>
                        (item.hours = [
                          ...item.hours.slice(0, item.hours.indexOf(hour)),
                          ...item.hours.slice(item.hours.indexOf(hour) + 1),
                        ])}
                    >
                      {#if tickBoxes}
                        <Tickboxes dark={textColor(item.color) === "#000000"} />
                      {/if}
                    </td>
                  {:else}
                    <td
                      style="text-align:center;font-style:italic"
                      on:dblclick={() => (item.hours = [...item.hours, hour])}
                    >
                      {#if hour === 10}
                        <Editable
                          cell
                          value={item.secondNote || ""}
                          on:change={(e) => {
                            item.secondNote = e.detail;
                            selectedDrugs = selectedDrugs;
                          }}
                        />
                      {:else}
                        <Editable
                          cell
                          value={item.hoursText?.[hour] || ""}
                          on:change={(e) => {
                            if (!item.hoursText) item.hoursText = {};
                            item.hoursText[hour] = e.detail;
                            selectedDrugs = selectedDrugs;
                          }}
                        />
                      {/if}</td
                    >
                  {/if}
                {/each}
              </tr>
            {/each}
          </tbody>
        </table>
      {:else}
        <div style="text-align:center;margin:2em;font-style:italic;">
          Enter treatment items...
        </div>
      {/if}
      {#if nextAppt}
        <div style="font-style:italic;text-align: center;margin:0.5em;">
          Next appointment: <Editable
            value={isDate(nextApptDate)
              ? format(nextApptDate, "EEEE, do MMMM yyyy @ h:mmaaa")
              : nextApptDate}
            on:change={(e) => {
              nextApptDate = e.detail;
              nextApptType = "text";
            }}
          />
        </div>
      {/if}
    </div>
    <!-- <div id="iframeContainer"><iframe id="pdfiframe" /></div> -->
  </div>
</div>

<style>
  #content {
    display: flex;
    align-items: stretch;
    height: 100%;
  }
  #sidebar,
  #output {
    overflow-y: scroll;
    padding: 10px;
    box-sizing: border-box;
  }
  #sidebar {
    width: 33%;
    background: #f9f9f9;
    border-right: 1px solid #999;
  }
  #output {
    width: 70%;
    background: #eee;
    position: relative;
  }
  @media (max-width: 768px) {
    #content {
      flex-direction: column;
    }
    #sidebar,
    #output {
      width: 100%;
      height: 50%;
    }
    #sidebar {
      border-right: none;
      border-bottom: 1px solid #999;
    }
  }
  #homesheet-dimensions {
    height: 21cm;
    width: 29.7cm;
  }
  #homesheet {
    background: #fff;
    height: 21cm;
    width: 29.7cm;
    box-shadow: 0 0 5px 1px #ccc;
    padding: 1.5cm 1cm;
    box-sizing: border-box;
    position: absolute;
    transform-origin: left top;
    top: 10px;
    left: 10px;
  }
  #pdfiframe {
    width: 100%;
    height: 500px;
  }
  table {
    border-collapse: collapse;
    width: 100%;
  }
  th {
    font-weight: normal;
    height: 1em;
  }
  th,
  td {
    border: 1px solid #666;
    padding: 0.2em 0.1em;
  }
  #homesheet thead :global(tr th) {
    border-top-width: 0;
    border-bottom-width: 0;
  }
  #homesheet thead :global(tr:first-child th) {
    border-top-width: 1px;
    vertical-align: top;
  }
  thead :global(tr:last-child th) {
    border-bottom-width: 1px;
    vertical-align: bottom;
  }
  :global(.editable) {
    overflow-wrap: anywhere;
  }
  label {
    display: block;
    font-family: Arial;
    font-size: 0.8em;
    margin-bottom: 0.25em;
  }
  :global(input[type="text"]),
  :global(select) {
    margin: 0;
    padding: 2px 5px;
  }
  :global(.autocomplete input),
  :global(.autocomplete select) {
    vertical-align: top;
  }
  :global(.autocomplete .input-container) {
    height: 100%;
  }
  .colorpicker-button {
    display: inline-block;
    background: #fff;
    border: 1px solid black;
    border-radius: 2px;
    min-height: 32px;
    width: 32px;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;
    vertical-align: top;
  }
  .colorpicker-button-color {
    position: absolute;
    top: 2px;
    left: 2px;
    right: 2px;
    bottom: 2px;
  }
  .colorpicker-popover {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.15);
    z-index: 2;
  }
  .colorpicker {
    position: absolute;
    z-index: 20;
    right: 0;
  }
  .reorder,
  .delete {
    font-size: 21px;
    display: flex;
    align-items: center;
  }
  .delete {
    font-size: 16px;
    cursor: pointer;
  }
  .reorder {
    cursor: grab;
  }
  .heading-name,
  .heading-date {
    text-align: center;
    text-decoration: underline;
    font-weight: bold;
    margin: 0.5em;
  }
  .eye-meds-info {
    color: red;
    font-style: italic;
    font-weight: bold;
    text-align: center;
    margin: 0.5em;
  }
  .treatment-plan {
    table-layout: fixed;
    min-height: 13cm;
    margin: 0 auto;
  }
  .drug-row {
    display: flex;
    align-items: stretch;
    position: relative;
    margin-bottom: 3px;
    height: 2em;
    flex-grow: 1;
  }
  .drug-row > :global(*) {
    margin-left: 3px;
  }
  .drug-size,
  .drug-eye {
    width: 20%;
  }
  .drug-freq {
    width: 14%;
  }
  .drug-dose {
    width: 35%;
  }
  :global(.autocomplete) {
    min-width: auto !important;
    width: 45% !important;
    height: auto !important;
    font-family: Arial;
    font-size: 13.333px;
  }
  .long :global(.autocomplete) {
    width: 65% !important;
  }
  :global(.autocomplete:not(.hide-arrow):not(.is-loading)::after) {
    height: 5px !important;
    width: 5px !important;
    margin-top: -5px !important;
    right: 10px !important;
  }
  :global(.autocomplete:not(.hide-arrow) .autocomplete-input) {
    padding-right: 15px !important;
  }
  :global(.autocomplete input) {
    padding-left: 5px !important;
    height: 100% !important;
  }
  :global(.autocomplete .input-container) {
    height: 100% !important;
  }
  :global(.autocomplete .autocomplete-list) {
    position: absolute !important;
    top: 100% !important;
    margin-top: 1px !important;
    padding: 5px 0 !important;
    border-radius: 2px !important;
    max-height: 15em !important;
  }
  :global(.autocomplete .autocomplete-list-item),
  :global(.autocomplete .autocomplete-list-item-no-results) {
    padding: 8px 10px !important;
  }
  .field {
    margin-bottom: 0.5em;
  }
  #controls {
    font-family: Arial;
    font-size: 0.8em;
    text-align: right;
    background: #fff;
    border: 1px solid #999;
    position: absolute;
    bottom: 0;
    right: 0;
    border-radius: 2px;
    margin: 5px;
    padding: 0.5em;
    opacity: 0.5;
    z-index: 5;
    display: flex;
    align-items: center;
  }
  #controls:hover {
    opacity: 1;
  }

  @page {
    width: 29.7cm;
    height: 21cm;
    margin: 2cm 1cm;
  }

  @media print {
    #content {
      display: block;
      height: 100%;
    }
    #sidebar {
      display: none;
    }
    #output {
      width: 100%;
      background: none;
    }
    #homesheet {
      box-shadow: none;
      zoom: normal !important;
      padding: 0;
      height: auto;
      width: 100%;
      margin: 0 auto;
    }
    #output {
      overflow: hidden;
    }
    #controls {
      display: none;
    }
  }
</style>
