import pdfMake from "pdfmake";
import { format, isDate } from "date-fns";

import applyVerticalAlignment from "./verticalAlign.js";

import {
  to12Hr,
  freqToText,
  textColor,
  replaceHtmlEntities,
  isObject,
  pencilSvg,
} from "./utils.js";

let pdfDocHeight = 595;
let pdfDocWidth = 842;
let hMargin = 28;
let vMargin = 42;
let pxPtRatio = 0.75;
let pdfContentWidth = pdfDocWidth - 2 * hMargin;

let href = window.location.href.replace(/#.*/, "").replace(/\/+$/, "");

pdfMake.fonts = {
  Arial: {
    normal: href + "/fonts/Arial.ttf",
    bold: href + "/fonts/Arial Bold.ttf",
  },
  "Times New Roman": {
    normal: href + "/fonts/Times New Roman.ttf",
    bold: href + "/fonts/Times New Roman Bold.ttf",
    italics: href + "/fonts/Times New Roman Italic.ttf",
    bolditalics: href + "/fonts/Times New Roman Bold Italic.ttf",
  },
};

function htmlToPdf(str) {
  let el = "";
  let output = [];
  let text = "";
  let config = {};

  function push() {
    if (!text) return;
    text = replaceHtmlEntities(text);
    output.push({ text, ...config });
    text = "";
  }

  for (let char of str) {
    if (char === "<") {
      el += char;
      continue;
    }
    if (el) el += char;
    if (el && char === ">") {
      if (el[1] !== "/") push();
      if (el === "<b>") config.bold = true;
      if (el === "<i>") config.italics = true;
      if (el === "<u>") config.decoration = "underline";
      if (el === "<br>") text += "\n";
      if (el[1] === "/") push();
      if (el === "</b>") delete config.bold;
      if (el === "</i>") delete config.italics;
      if (el === "</u>") delete config.decoration;
      el = "";
      continue;
    }
    if (!el) text += char;
  }
  push();
  output = output.map((bit) =>
    bit.bold || bit.italics || bit.decoration ? bit : bit.text
  );
  if (output.length === 1) return output[0];
  return { text: output };
}

function deepHtmlToPdf(o) {
  let out;
  if (Array.isArray(o)) {
    out = o.map(deepHtmlToPdf);
  } else if (isObject(o)) {
    out = {};
    for (let prop in o) {
      if (!o.hasOwnProperty(prop)) continue;
      out[prop] = deepHtmlToPdf(o[prop]);
    }
  } else if (typeof o === "string") out = htmlToPdf(o);
  else out = o;

  return out;
}

function planHeader(tableColumns, pdfTextSize) {
  return tableColumns.map((item, i) => {
    if (item.id === "hr" || item.id === "gap") return "";
    return {
      fillColor: item.color,
      color: textColor(item.color),
      layout: {
        hLineWidth: () => 0,
        vLineWidth: () => 0,
        paddingLeft: () => 0,
        paddingRight: () => 0,
        paddingBottom: () => 0,
        paddingTop: () => 0,
      },
      margin: 0.2 * pdfTextSize,
      table: {
        widths: ["100%"],
        heights(rowIndex) {
          let tr = document.querySelector(`#homesheet th:nth-child(${i + 1})`);
          let top = tr.querySelector(".top");
          let bottom = tr.querySelector(".bottom");

          if (rowIndex === 0) return top.offsetHeight * 0.75;
          if (rowIndex === 1) return bottom.offsetHeight * 0.75;
        },
        body: [
          [
            [
              {
                text: [item.name, item.size ? " " : "", item.size],
                bold: true,
              },
              { text: ["(", item.use, ")"], fontSize: 0.6 * pdfTextSize },
              { text: item.eye, italics: true },
              { text: item.note, italics: true },
            ],
          ],
          [[item.dose, freqToText(item.frequency)]],
        ],
      },
    };
  });
}

function planHeaderBottom(tableColumns, pdfTextSize) {
  return tableColumns.map((item, i) => {
    if (item.id === "hr" || item.id === "gap") return "";
    return {
      fillColor: item.color,
      color: textColor(item.color),
      layout: {
        hLineWidth: () => 0,
        vLineWidth: () => 0,
        paddingLeft: () => 0,
        paddingRight: () => 0,
        paddingBottom: () => 0,
        paddingTop: () => 0,
      },
      margin: 0.2 * pdfTextSize,
      stack: [item.dose, freqToText(item.frequency)],
    };
  });
}

function planHeaderTop(tableColumns, pdfTextSize) {
  return tableColumns.map((item, i) => {
    if (item.id === "hr" || item.id === "gap") return "";
    return {
      fillColor: item.color,
      color: textColor(item.color),
      stack: [
        {
          text: [item.name, item.size ? " " : "", item.size],
          bold: true,
        },
        { text: ["(", item.use, ")"], fontSize: 0.6 * pdfTextSize },
        { text: item.eye, italics: true },
        { text: item.note, italics: true },
      ],
    };
  });
}

function tickbox(config = {}) {
  return {
    canvas: [
      {
        type: "rect",
        x: 0,
        y: 0,
        w: 10,
        h: 10,
        lineWidth: 0.5,
        lineColor: "#000",
        color: "#fff",
        fillOpacity: 0.5,
        strokeOpacity: 0.5,
        ...config,
      },
    ],
  };
}

function tickboxes(n, config) {
  return Array.from({ length: n }, (val) => tickbox(config));
}

function planRow(hr, tableColumns, pdfTextSize, tickBoxes) {
  return tableColumns.map((item) => {
    let dark = item.color ? textColor(item.color) === "#000000" : false;

    if (item.id === "hr")
      return {
        text: to12Hr(hr),
        margin: [1, 3 + Math.max(0, (10.5 - pdfTextSize) * 0.6)],
      };
    if (item.id === "gap") return "";
    if (item.hours.indexOf(hr) > -1)
      return {
        fillColor: item.color,
        stack: tickBoxes
          ? [
              centerElement({
                width: "auto",
                font: "Arial",
                bold: true,
                fontSize: 0.7 * pdfTextSize,
                style: {
                  opacity: dark ? 0.6 : 0.9,
                  color: textColor(item.color),
                },
                margin: [0, 2],
                table: {
                  body: [
                    ["M", "T", "W", "T", "F", "S", "S"],
                    tickboxes(7, {
                      h: 0.7 * pdfTextSize - 1,
                      w: 0.7 * pdfTextSize - 1,
                      lineColor: textColor(item.color),
                      strokeOpacity: dark ? 0.5 : 0.9,
                    }),
                  ],
                },
                layout: {
                  defaultBorder: false,
                  paddingLeft: () => (tableColumns.length < 13 ? 1 : 0),
                  paddingRight: () => (tableColumns.length < 13 ? 1 : 0),
                  paddingBottom: () => 0,
                  paddingTop: () => 0,
                },
              }),
            ]
          : [],
      };
    if (hr === 10) return { text: item.secondNote, italics: true, margin: 2 };
    return item.hoursText
      ? { text: item.hoursText[hr], italics: true, margin: 2 }
      : "";
    return "";
  });
}
function planBody(visibleHours, tableColumns, pdfTextSize, tickBoxes) {
  return visibleHours.map((hr) =>
    planRow(hr, tableColumns, pdfTextSize, tickBoxes)
  );
}

function centerElement(el) {
  return {
    columns: [{ width: "*", text: "" }, el, { width: "*", text: "" }],
  };
}

export default function createPDF({
  textSize,
  gapWidth,
  tableColumns,
  planPct,
  animalName,
  clientName,
  selectedDrugs,
  tickBoxes,
  nextAppt,
  nextApptDate,
  visibleHours,
  title,
  date,
}) {
  let pdfTextSize = textSize * pxPtRatio;
  let pdfGapWidth = gapWidth * pxPtRatio;
  let drugCount = 0;
  let eyeDrugCount = 0;
  let gapCount = 0;

  for (let item of tableColumns) {
    if (item.id === "hr" || item.id === "gap") gapCount++;
    else drugCount++;
    if (item.formulation === "eyeTopical") eyeDrugCount++;
  }

  let availableWidth =
    pdfContentWidth * (planPct / 100) - gapCount * pdfGapWidth;
  let colWidth = availableWidth / drugCount;

  let pdfCellWidths = tableColumns.map((item) =>
    item.id === "hr" || item.id === "gap" ? pdfGapWidth : colWidth
  );

  tableColumns = deepHtmlToPdf(tableColumns);

  let docDefinition = {
    info: {
      title,
      author: "Rowe Referrals",
    },
    pageSize: "A4",
    pageOrientation: "landscape",
    pageMargins: [hMargin, vMargin, hMargin, 0],
    defaultStyle: {
      fontSize: pdfTextSize,
      font: "Times New Roman",
      alignment: "center",
    },
    content: [
      {
        text: ["Name: ", animalName, " ", clientName],
        bold: true,
        decoration: "underline",
        margin: [0, 0, 0, pdfTextSize * 0.5],
        fontSize: 10.5,
      },
      {
        text: "Date: " + format(date, "EEEE, do MMMM yyyy"),
        bold: true,
        decoration: "underline",
        margin: [0, 0, 0, pdfTextSize * 0.5],
        fontSize: 10.5,
      },
      {
        text:
          eyeDrugCount > 1
            ? "Please allow five minutes between eye medications"
            : "",
        italics: true,
        bold: true,
        color: "#ff0000",
        margin: [0, 0, 0, pdfTextSize * 0.5],
        fontSize: 10.5,
      },
      selectedDrugs.length
        ? centerElement({
            width: "auto",
            table: {
              widths: pdfCellWidths,
              body: [
                planHeaderTop(tableColumns, pdfTextSize),
                planHeaderBottom(tableColumns, pdfTextSize),
                ...planBody(visibleHours, tableColumns, pdfTextSize, tickBoxes),
              ],
            },
            layout: {
              hLineWidth: (index) => (index === 1 ? 0 : 0.5),
              vLineWidth: () => 0.5,
              paddingLeft: () => 0,
              paddingRight: () => 0,
              paddingBottom: (index) => (index === 1 ? 0.1 * pdfTextSize : 0),
              paddingTop: function (index, node) {
                if (index === 0) return 0.2 * pdfTextSize;
                if (index === 1) applyVerticalAlignment(node, index, "bottom");
                if (index > 1) applyVerticalAlignment(node, index, "center");
                return 0;
              },
            },
          })
        : { text: "Enter treatment items", italics: true },
      nextAppt && nextApptDate
        ? {
            text: [
              "Next appointment: ",
              isDate(nextApptDate)
                ? format(nextApptDate, "EEEE, do MMMM yyyy @ h:mmaaa")
                : htmlToPdf(nextApptDate),
            ],
            italics: true,
            margin: [0, pdfTextSize * 0.5, 0, 0],
            fontSize: 10.5,
          }
        : undefined,
      {
        svg: pencilSvg(location.href),
        fit: [14, 14],
        margin: 0,
        alignment: "left",
        absolutePosition: {
          x: pdfDocWidth - 28,
          y: pdfDocHeight - 28,
        },
      },
    ],
  };

  return pdfMake.createPdf(docDefinition);
}
