<script>
  import { onMount, createEventDispatcher } from "svelte";

  export let value;
  export let cell = undefined;
  let div;
  let html;
  let active = false;

  let dispatch = createEventDispatcher();

  onMount(() => {
    div.innerHTML = value;
    let fn = () => div.focus();
    div.parentNode.addEventListener("click", fn);

    return () => {
      div.parentNode.removeEventListener("click", fn);
    };
  });

  function handleChange(e) {
    for (let node of div.childNodes) {
      switch (node.nodeName) {
        case "#text":
        case "B":
        case "I":
        case "U":
        case "BR":
          continue;
        default:
          node.replaceWith(node.textContent);
      }
    }

    html = div.innerHTML.replace(/<br>$/, "");

    value = html;
    dispatch("change", value);
  }

  function onKeyDown(e) {
    let sel = window.getSelection();
    let { anchorOffset, focusOffset } = sel;

    if (e.which === 13) {
      e.preventDefault();
      document.execCommand("insertLineBreak");
    }

    let table = div.closest("table");
    let row = div.closest("tr");
    let cell = div.closest("td, th");

    if (!table) return;

    let cellEditables = Array.from(cell.querySelectorAll(".editable"));
    let divIndexInCell = cellEditables.indexOf(div);
    let len = div.textContent.length;

    let startOfEditable = anchorOffset === 0 && focusOffset === 0;
    let endOfEditable = anchorOffset === len && focusOffset === len;
    let startOfCell = startOfEditable && divIndexInCell === 0;
    let endOfCell =
      endOfEditable && divIndexInCell === cellEditables.length - 1;

    let rowEditables = Array.from(row.querySelectorAll(".editable"));
    let cellIndexInRow = Array.from(row.querySelectorAll("td, th")).indexOf(
      cell
    );
    let divIndexInRow = rowEditables.indexOf(div);

    let colEditables = Array.from(
      table.querySelectorAll(
        `th:nth-child(${cellIndexInRow + 1}) .editable, td:nth-child(${
          cellIndexInRow + 1
        }) .editable`
      )
    );
    let divIndexInCol = colEditables.indexOf(div);

    let startOfRow = startOfEditable && divIndexInRow === 0;
    let endOfRow = endOfEditable && divIndexInRow === rowEditables.length - 1;
    let startOfCol = startOfEditable && divIndexInCol === 0;
    let endOfCol = endOfEditable && divIndexInCol === colEditables.length - 1;

    if ((e.which === 37 || e.which === 38) && startOfEditable && !startOfCell) {
      e.preventDefault();
      cellEditables[divIndexInCell - 1].focus();
    }
    if ((e.which === 39 || e.which === 40) && endOfEditable && !endOfCell) {
      e.preventDefault();
      cellEditables[divIndexInCell + 1].focus();
    }
    if (e.which === 38 && startOfEditable && startOfCell && !startOfCol) {
      e.preventDefault();
      colEditables[divIndexInCol - 1].focus();
    }
    if (e.which === 40 && endOfEditable && endOfCell && !endOfCol) {
      e.preventDefault();
      colEditables[divIndexInCol + 1].focus();
    }
    if (e.which === 37 && startOfEditable && startOfCell && !startOfRow) {
      e.preventDefault();
      rowEditables[divIndexInRow - 1].focus();
    }
    if (e.which === 39 && endOfEditable && endOfCell && !endOfRow) {
      e.preventDefault();
      rowEditables[divIndexInRow + 1].focus();
    }
  }

  $: if (!active && div && value !== html) {
    div.innerHTML = value;
  }
</script>

<div
  class="editable"
  class:cell
  contenteditable="true"
  bind:this={div}
  on:input={handleChange}
  on:keydown={onKeyDown}
  on:focus={() => (active = true)}
  on:blur={() => (active = false)}
/>

<style>
  div {
    display: inline;
    box-sizing: border-box;
  }
  .cell {
    display: inline-block;
    width: 100%;
  }
  div:focus-visible {
    outline: 2px dotted blue;
    padding: 0.2em 0.5em;
    margin: -0.2em -0.5em;
  }
</style>
