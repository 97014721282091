export function RGBToHex({ r, g, b }) {
  r = r.toString(16);
  g = g.toString(16);
  b = b.toString(16);

  if (r.length == 1) r = "0" + r;
  if (g.length == 1) g = "0" + g;
  if (b.length == 1) b = "0" + b;

  return "#" + r + g + b;
}

export function hexToRGB(h) {
  let r = 0,
    g = 0,
    b = 0;

  // 3 digits
  if (h.length === 4) {
    r = "0x" + h[1] + h[1];
    g = "0x" + h[2] + h[2];
    b = "0x" + h[3] + h[3];

    // 6 digits
  } else if (h.length === 7) {
    r = "0x" + h[1] + h[2];
    g = "0x" + h[3] + h[4];
    b = "0x" + h[5] + h[6];
  }

  return { r, g, b };
}

export function freqToHours(freq) {
  switch (freq) {
    case "qid":
      return [8, 13, 18, 23];
    case "tid":
      return [8, 18, 23];
    case "bid":
      return [8, 18];
    case "sid":
      return [8];
    default:
      return [];
  }
}

export function to12Hr(i) {
  if (i < 12) return i + "am";
  if (i === 12) return "12pm";
  if (i === 24) return "12am";
  return i - 12 + "pm";
}

export function freqToText(freq) {
  switch (freq) {
    case "qid":
      return "Four times daily";
    case "tid":
      return "Three times daily";
    case "bid":
      return "Twice daily";
    case "sid":
      return "Once daily";
    default:
      return freq;
  }
}

export function textToFreq(txt) {
  switch (txt) {
    case "Four times daily":
      return "qid";
    case "Three times daily":
      return "tid";
    case "Twice daily":
      return "bid";
    case "Once daily":
      return "sid";
    default:
      return txt;
  }
}

export function textColor(h) {
  let { r, g, b } = hexToRGB(h);
  let brightness = r * 0.299 + g * 0.587 + b * 0.114;

  if (brightness > 100) return "#000000";
  return "#ffffff";
}

export function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    }, wait);
    if (immediate && !timeout) func.apply(context, args);
  };
}

const STANDARD_HTML_ENTITIES = {
  nbsp: String.fromCharCode(160),
  amp: "&",
  quot: '"',
  lt: "<",
  gt: ">",
};

export function replaceHtmlEntities(plainTextString) {
  return plainTextString
    .replace(/&#(\d+);/g, (match, dec) => String.fromCharCode(dec))
    .replace(/&(nbsp|amp|quot|lt|gt);/g, (a, b) => STANDARD_HTML_ENTITIES[b]);
}

export function isObject(obj) {
  var type = typeof obj;
  return type === "function" || (type === "object" && !!obj);
}

export function pencilSvg(href) {
  return `
    <?xml version="1.0" encoding="UTF-8"?>
    <svg enable-background="new 0 0 512 512" version="1.1" viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
        <a href="${href}">
            <path d="m493.88 100.44c-11.702-11.701-27.259-18.145-43.807-18.145s-32.161 6.444-43.862 18.145l-37.254 37.197v-121.88c0-7.604-6.053-13.767-13.657-13.767h-231.29c-3.651 0-7.153 1.451-9.735 4.032l-110.19 110.14c-2.581 2.581-4.087 6.083-4.087 9.734v370.34c0 7.604 6.274 13.767 13.877 13.767h341.42c7.604 0 13.657-6.164 13.657-13.767v-183.37l124.87-124.81c11.702-11.702 18.174-27.259 18.174-43.807s-6.417-32.105-18.118-43.807zm-383.74-51.441v63.133h-63.023l63.023-63.133zm231.29 433.47h-313.89v-342.8h96.48c7.604 0 13.657-6.164 13.657-13.767v-96.371h203.75v135.64l-154.25 154.3c-0.067 0.068-0.096 0.14-0.161 0.209-1.759 1.714-3.054 3.888-3.701 6.347l-24.329 92.481c-1.246 4.735 0.12 9.776 3.584 13.239 2.614 2.614 6.132 4.032 9.737 4.032 1.167 0 2.346-0.149 3.504-0.453l92.481-24.338c2.457-0.647 4.631-1.955 6.347-3.713 0.069-0.066 0.088-0.122 0.156-0.19l66.63-66.687v142.07zm-137.57-126.25 34.247 34.246-46.478 12.231 12.231-46.477zm61.254 21.666-48.674-48.674 175.23-175.23 48.674 48.674-175.23 175.23zm209.3-209.3-14.601 14.603-48.674-48.674 14.601-14.601c6.501-6.501 15.144-10.082 24.338-10.082 9.192 0 17.837 3.581 24.338 10.082s10.08 15.144 10.08 24.336-3.582 17.837-10.082 24.336z"/>
            <path d="m117.13 408.13h-39.925c-7.604 0-13.767 6.164-13.767 13.767 0 7.604 6.164 13.767 13.767 13.767h39.925c7.604 0 13.767-6.164 13.767-13.767 0-7.604-6.164-13.767-13.767-13.767z"/>
            <path d="m124.01 353.06h-46.808c-7.604 0-13.767 6.164-13.767 13.767 0 7.604 6.164 13.767 13.767 13.767h46.808c7.604 0 13.767-6.164 13.767-13.767 1e-3 -7.603-6.163-13.767-13.767-13.767z"/>
            <path d="m137.78 297.99h-60.576c-7.604 0-13.767 6.164-13.767 13.767 0 7.604 6.164 13.767 13.767 13.767h60.575c7.604 0 13.767-6.164 13.767-13.767 1e-3 -7.604-6.163-13.767-13.766-13.767z"/>
            <path d="m177.71 242.92h-100.5c-7.604 0-13.767 6.164-13.767 13.767 0 7.604 6.164 13.767 13.767 13.767h100.5c7.604 0 13.767-6.164 13.767-13.767s-6.163-13.767-13.767-13.767z"/>
            <path d="m234.15 187.85h-156.95c-7.604 0-13.767 6.164-13.767 13.767s6.164 13.767 13.767 13.767h156.94c7.604 0 13.767-6.164 13.767-13.767s-6.163-13.767-13.766-13.767z"/>
            <path d="m297.48 132.78h-118.4c-7.604 0-13.767 6.164-13.767 13.767 0 7.604 6.164 13.767 13.767 13.767h118.4c7.604 0 13.767-6.164 13.767-13.767 1e-3 -7.603-6.163-13.767-13.767-13.767z"/>
            <path d="m297.48 77.715h-118.4c-7.604 0-13.767 6.164-13.767 13.767s6.164 13.767 13.767 13.767h118.4c7.604 0 13.767-6.164 13.767-13.767s-6.163-13.767-13.767-13.767z"/>
        </a>
    </svg>
    `;
}
