const drugs = [
  {
    name: "Exocin",
    formulation: "eyeTopical",
    use: "antibiotic",
    frequency: "qid",
    color: "#fcdc41",
    dose: "One drop",
  },
  {
    name: "Oftaquix",
    formulation: "eyeTopical",
    use: "antibiotic",
    frequency: "qid",
    color: "#fcdc41",
    dose: "One drop",
  },
  {
    name: "Serum",
    formulation: "eyeTopical",
    use: "anti-collagenase",
    frequency: "qid",
    color: "#ffddcc",
    dose: "One drop",
    secondNote: "Keep in fridge",
  },
  {
    name: "EyeQ Amniotic Eye Drops",
    formulation: "eyeTopical",
    use: "amnion drops",
    frequency: "qid",
    color: "#ffddcc",
    dose: "One drop",
  },
  {
    name: "Clinitas Soothe",
    formulation: "eyeTopical",
    use: "tear supplement",
    frequency: "qid",
    color: "#23b8b8",
    dose: "One drop",
    eye: "Both eyes",
  },
  {
    name: "Thealoz Duo",
    formulation: "eyeTopical",
    use: "tear supplement",
    frequency: "qid",
    color: "#44ee66",
    dose: "One drop",
  },
  {
    name: "Thealoz Duo Gel",
    formulation: "eyeTopical",
    use: "tear supplement",
    frequency: "qid",
    color: "#44ee66",
    dose: "One drop",
  },
  {
    name: "anHypro",
    formulation: "eyeTopical",
    use: "tear supplement",
    frequency: "qid",
    color: "#23b8b8",
    dose: "One drop",
  },
  {
    name: "Remend",
    formulation: "eyeTopical",
    use: "tear supplement",
    frequency: "qid",
    color: "#23b8b8",
    dose: "One drop",
  },
  {
    name: "Chloramphenicol",
    formulation: "eyeTopical",
    use: "antibiotic",
    frequency: "qid",
    color: "#33ee55",
    dose: "One drop",
    secondNote: "Keep in fridge",
  },
  {
    name: "Orbenin",
    formulation: "eyeTopical",
    use: "antibiotic",
    frequency: "qid",
    color: "#eedd55",
    dose: "One drop",
  },
  {
    name: "Optimmune",
    formulation: "eyeTopical",
    use: "tear stimulant / immunomodulator",
    frequency: "bid",
    color: "#eeaaaa",
    dose: "Small rice-grain amount",
  },
  {
    name: "Pred Forte",
    formulation: "eyeTopical",
    use: "steroid anti-inflammatory",
    frequency: "qid",
    color: "#ff0000",
    textColor: "#ffffff",
    dose: "One drop",
  },
  {
    name: "Yellox",
    formulation: "eyeTopical",
    use: "non-steroidal anti-inflammatory",
    frequency: "bid",
    color: "#9999ff",
    dose: "One drop",
  },
  {
    name: "Acular",
    formulation: "eyeTopical",
    use: "non-steroidal anti-inflammatory",
    frequency: "bid",
    color: "#9999ff",
    dose: "One drop",
  },
  {
    name: "Tacrolimus",
    sizes: ["0.1%", "0.03%"],
    size: "0.1%",
    formulation: "eyeTopical",
    use: "tear stimulant / immunomodulator",
    frequency: "tid",
    color: "#33ee44",
    dose: "Small rice-grain amount",
  },
  {
    name: "Protopic",
    sizes: ["0.1%", "0.03%"],
    size: "0.1%",
    formulation: "eyeTopical",
    use: "tear stimulant / immunomodulator",
    frequency: "tid",
    color: "#33ee44",
    dose: "Small rice-grain amount",
  },
  {
    name: "Tacrolimus 0.02%",
    formulation: "eyeTopical",
    use: "tear stimulant / immunomodulator",
    frequency: "tid",
    color: "#33ee44",
    dose: "One drop",
  },
  {
    name: "Isathal",
    formulation: "eyeTopical",
    use: "antibiotic",
    frequency: "bid",
    color: "#ee9900",
    dose: "Small amount",
  },
  {
    name: "Tiacil",
    formulation: "eyeTopical",
    use: "antibiotic",
    frequency: "tid",
    color: "#334499",
    dose: "Small amount",
  },
  {
    name: "Gentamicin",
    formulation: "eyeTopical",
    use: "antibiotic",
    frequency: "tid",
    color: "#334499",
    dose: "Small amount",
  },
  {
    name: "Travatan",
    formulation: "eyeTopical",
    use: "controls eye pressure",
    frequency: "bid",
    color: "#3399cc",
    dose: "One drop",
  },
  {
    name: "DuoTrav",
    formulation: "eyeTopical",
    use: "controls eye pressure",
    frequency: "bid",
    color: "#3399cc",
    dose: "One drop",
  },
  {
    name: "Latanoprost",
    formulation: "eyeTopical",
    use: "controls eye pressure",
    frequency: "bid",
    color: "#3399cc",
    dose: "One drop",
  },
  {
    name: "Azopt",
    formulation: "eyeTopical",
    use: "controls eye pressure",
    frequency: "bid",
    color: "#33ee66",
    dose: "One drop",
  },
  {
    name: "Azarga",
    formulation: "eyeTopical",
    use: "controls eye pressure",
    frequency: "bid",
    color: "#cc33cc",
    dose: "One drop",
  },
  {
    name: "Cosopt",
    formulation: "eyeTopical",
    use: "controls eye pressure",
    frequency: "bid",
    color: "#cc33cc",
    dose: "One drop",
  },
  {
    name: "Trusopt",
    formulation: "eyeTopical",
    use: "controls eye pressure",
    frequency: "bid",
    color: "#cc33cc",
    dose: "One drop",
  },
  {
    name: "Sodium Chloride",
    formulation: "eyeTopical",
    use: "reduce oedema",
    frequency: "qid",
    color: "#222277",
    dose: "Small amount",
  },
  {
    name: "Muro",
    formulation: "eyeTopical",
    use: "reduce oedema",
    frequency: "qid",
    color: "#222277",
    dose: "Small amount",
  },
  {
    name: "Maxidex",
    formulation: "eyeTopical",
    use: "steroid anti-inflammatory",
    frequency: "qid",
    color: "#996600",
    dose: "One drop",
  },
  {
    name: "Maxitrol",
    formulation: "eyeTopical",
    use: "steroid anti-inflammatory / antibiotic",
    frequency: "qid",
    color: "#996600",
    dose: "One drop",
  },
  {
    name: "Tobradex",
    formulation: "eyeTopical",
    use: "steroid anti-inflammatory / antibiotic",
    frequency: "qid",
    color: "#3366cc",
    dose: "One drop",
  },
  {
    name: "Ophthocycline",
    formulation: "eyeTopical",
    use: "antibiotic",
    frequency: "qid",
    color: "#ccaa33",
    dose: "Small amount",
  },
  {
    name: "Virgan",
    formulation: "eyeTopical",
    use: "antiviral",
    frequency: "qid",
    color: "#cc1199",
    dose: "Small amount",
  },
  {
    name: "Optixcare Eye Lube Plus",
    formulation: "eyeTopical",
    use: "lubricant",
    frequency: "qid",
    color: "#cc1199",
    dose: "Small amount",
  },
  {
    name: "Voriconazole",
    formulation: "eyeTopical",
    use: "antifungal",
    frequency: "qid",
    color: "#cc1199",
    dose: "One drop",
  },
  {
    name: "Terbinafine",
    formulation: "eyeTopical",
    use: "antifungal",
    frequency: "qid",
    color: "#cc1199",
    dose: "Small amount",
  },
  {
    name: "Atropine",
    formulation: "eyeTopical",
    use: "dilate pupil",
    frequency: "sid",
    color: "#cc1199",
    dose: "One drop",
  },
  {
    name: "Cyclopentolate",
    formulation: "eyeTopical",
    use: "dilate pupil",
    frequency: "bid",
    color: "#cc1199",
    dose: "One drop",
  },
  {
    name: "Phenylephrine",
    formulation: "eyeTopical",
    use: "dilate pupil",
    frequency: "bid",
    color: "#cc1199",
    dose: "One drop",
  },
  {
    name: "Tropicamide",
    formulation: "eyeTopical",
    use: "dilate pupil",
    frequency: "bid",
    color: "#cc1199",
    dose: "One drop",
  },
  {
    name: "Mydriacyl",
    formulation: "eyeTopical",
    use: "dilate pupil",
    frequency: "bid",
    color: "#cc1199",
    dose: "One drop",
  },
  {
    name: "Pilocarpine 0.2%",
    formulation: "eyeTopical",
    use: "tear stimulant",
    frequency: "qid",
    color: "#9944ff",
    dose: "One drop",
  },
  {
    name: "Pilocarpine",
    sizes: ["1%", "2%", "4%"],
    size: "1%",
    formulation: "oral",
    use: "tear stimulant",
    frequency: "bid",
    color: "#9944ff",
    dose: "One drop in food",
    secondNote:
      "Increase dose by one drop every 3 days until side effects. Then reduce to last well-tolerated dose.",
  },
  {
    name: "Clerapliq",
    formulation: "eyeTopical",
    use: "dilate pupil",
    frequency: "sid",
    color: "#aaaaaa",
    dose: "One drop",
  },
  {
    name: "Hylo Night",
    formulation: "eyeTopical",
    use: "dry eye",
    frequency: "sid",
    color: "#aaaaaa",
    dose: "Small amount",
  },
  {
    name: "Hyabak",
    formulation: "eyeTopical",
    use: "tear supplement",
    frequency: "sid",
    color: "#6677ee",
    dose: "Small amount",
  },
  {
    name: "Viscotears",
    formulation: "eyeTopical",
    use: "tear supplement",
    frequency: "sid",
    color: "#3990ed",
    dose: "Small amount",
  },
  {
    name: "Twelve",
    formulation: "eyeTopical",
    use: "tear supplement",
    frequency: "sid",
    color: "#3990ed",
    dose: "Small amount",
  },
  {
    name: "Viskyal",
    formulation: "eyeTopical",
    use: "tear supplement",
    frequency: "sid",
    color: "#3990ed",
    dose: "Small amount",
  },
  {
    name: "Stromease",
    formulation: "eyeTopical",
    use: "anti-collagenase / mucolytic",
    frequency: "qid",
    color: "#3990ed",
    dose: "Small amount",
  },
  {
    name: "Ilube",
    formulation: "eyeTopical",
    use: "anti-collagenase / mucolytic",
    frequency: "qid",
    color: "#3990ed",
    dose: "Small amount",
  },
  {
    name: "Ocry-gel",
    formulation: "eyeTopical",
    use: "lubricant",
    frequency: "sid",
    color: "#3990ed",
    dose: "Small amount",
  },
  {
    name: "Xialin Night",
    formulation: "eyeTopical",
    use: "dry eye",
    frequency: "sid",
    color: "#aaaaaa",
    dose: "Small amount",
  },
  {
    name: "Saline Flush",
    formulation: "eyeTopical",
    use: "remove mucus",
    frequency: "sid",
    color: "#8888ee",
    dose: "",
  },
  {
    name: "Loxicom",
    formulation: "oral",
    use: "anti-inflammatory painkiller",
    frequency: "sid",
    color: "#f0d586",
    dose: "_kg dose",
    note: "With food",
    secondNote: "Stop and call if any sickness/diarrhoea",
  },
  {
    name: "Metacam",
    formulation: "oral",
    use: "anti-inflammatory painkiller",
    frequency: "sid",
    color: "#f022ee",
    dose: "_kg dose",
    note: "With food",
    secondNote: "Stop and call if any sickness/diarrhoea",
  },
  {
    name: "Inflacam",
    formulation: "oral",
    use: "anti-inflammatory painkiller",
    frequency: "sid",
    color: "#f022ee",
    dose: "_kg dose",
    note: "With food",
    secondNote: "Stop and call if any sickness/diarrhoea",
  },
  {
    name: "Rimadyl",
    sizes: ["20mg", "50mg", "100mg"],
    size: "20mg",
    formulation: "oral",
    use: "anti-inflammatory painkiller",
    frequency: "sid",
    color: "#f022ee",
    dose: "1 tablet",
    note: "With food",
    secondNote: "Stop and call if any sickness/diarrhoea",
  },
  {
    name: "Carprieve",
    sizes: ["20mg", "50mg", "100mg"],
    size: "20mg",
    formulation: "oral",
    use: "anti-inflammatory painkiller",
    frequency: "sid",
    color: "#f022ee",
    dose: "1 tablet",
    note: "With food",
    secondNote: "Stop and call if any sickness/diarrhoea",
  },
  {
    name: "Carprodyl Quadri",
    sizes: ["50mg", "120mg"],
    size: "50mg",
    formulation: "oral",
    use: "anti-inflammatory painkiller",
    frequency: "sid",
    color: "#f022ee",
    dose: "1 tablet",
    note: "With food",
    secondNote: "Stop and call if any sickness/diarrhoea",
  },
  {
    name: "YuMove",
    formulation: "oral",
    use: "supplement",
    frequency: "sid",
    color: "#f022ee",
    dose: "1 tablet",
  },
  {
    name: "Flexadin",
    formulation: "oral",
    use: "supplement",
    frequency: "sid",
    color: "#f022ee",
    dose: "1 tablet",
  },
  {
    name: "Rilexine",
    sizes: ["75mg", "300mg", "600mg"],
    size: "300mg",
    formulation: "oral",
    use: "antibiotic",
    frequency: "bid",
    color: "#265FB9",
    dose: "1 tablet",
  },
  {
    name: "Clavaseptin",
    sizes: ["50mg", "62.5mg", "250mg", "500mg"],
    size: "250mg",
    formulation: "oral",
    use: "antibiotic",
    frequency: "bid",
    color: "#ff99bb",
    dose: "1 tablet",
  },
  {
    name: "Synulox",
    sizes: ["50mg", "250mg", "500mg"],
    size: "250mg",
    formulation: "oral",
    use: "antibiotic",
    frequency: "bid",
    color: "#ff99bb",
    dose: "1 tablet",
  },
  {
    name: "Marbocyl",
    sizes: ["5mg", "20mg", "80mg"],
    size: "20mg",
    formulation: "oral",
    use: "antibiotic",
    frequency: "sid",
    color: "#ff99bb",
    dose: "1 tablet",
  },
  {
    name: "Doxycare",
    sizes: ["40mg", "200mg"],
    size: "200mg",
    formulation: "oral",
    use: "antibiotic",
    frequency: "sid",
    color: "#3366ff",
    dose: "1 tablet",
    note: "With food",
  },
  {
    name: "Ronaxan",
    sizes: ["20mg", "100mg"],
    size: "100mg",
    formulation: "oral",
    use: "antibiotic",
    frequency: "sid",
    color: "#3366ff",
    dose: "1 tablet",
    note: "With food",
  },
  {
    name: "Doxycycline Paste",
    formulation: "oral",
    use: "antibiotic",
    frequency: "sid",
    color: "#3366ff",
    dose: "_ml",
  },
  {
    name: "Metrocare",
    sizes: ["250mg", "500mg"],
    size: "100mg",
    formulation: "oral",
    use: "antibiotic",
    frequency: "bid",
    color: "#bb9933",
    dose: "1 tablet",
    note: "With food",
  },
  {
    name: "Metrobactin",
    sizes: ["250mg", "500mg"],
    size: "100mg",
    formulation: "oral",
    use: "antibiotic",
    frequency: "bid",
    color: "#99aaee",
    dose: "1 tablet",
    note: "With food",
  },
  {
    name: "Prednisolone",
    sizes: ["1mg", "5mg", "25mg"],
    size: "5mg",
    formulation: "oral",
    use: "steroid anti-inflammatory",
    frequency: "sid",
    color: "#999999",
    dose: "1 tablet",
  },
  {
    name: "Apoquel",
    sizes: ["3.6mg", "5.4mg", "16mg"],
    size: "16mg",
    formulation: "oral",
    use: "anti-itch",
    frequency: "sid",
    color: "#999999",
    dose: "1 tablet",
  },
  {
    name: "Atopica",
    sizes: ["10mg", "25mg", "50mg", "100mg", "solution"],
    size: "50mg",
    formulation: "oral",
    use: "immunosuppressant",
    frequency: "sid",
    color: "#999999",
    dose: "1 tablet",
  },
  {
    name: "Onsior",
    sizes: ["10mg", "20mg", "40mg"],
    size: "10mg",
    formulation: "oral",
    use: "anti-inflammatory painkiller",
    frequency: "sid",
    color: "#3344ee",
    dose: "1 tablet",
    secondNote: "Stop and call if any sickness/diarrhoea",
  },
  {
    name: "Previcox",
    sizes: ["57mg", "227mg"],
    size: "57mg",
    formulation: "oral",
    use: "anti-inflammatory painkiller",
    frequency: "sid",
    color: "#3344ee",
    dose: "1 tablet",
    secondNote: "Stop and call if any sickness/diarrhoea",
  },
  {
    name: "Melacutin",
    sizes: ["3mg"],
    size: "3mg",
    formulation: "oral",
    use: "anti-inflammatory",
    frequency: "bid",
    color: "#6666cc",
    dose: "1 tablet",
  },
  {
    name: "Tralieve",
    sizes: ["20mg", "80mg"],
    size: "80mg",
    formulation: "oral",
    use: "painkiller",
    frequency: "tid",
    color: "#6666cc",
    dose: "1 tablet",
  },
  {
    name: "Tramadol",
    sizes: ["50mg"],
    size: "50mg",
    formulation: "oral",
    use: "painkiller",
    frequency: "tid",
    color: "#6666cc",
    dose: "1 capsule",
  },
  {
    name: "Pardale-V",
    formulation: "oral",
    use: "painkiller",
    frequency: "tid",
    color: "#6666cc",
    dose: "1 tablet",
  },
  {
    name: "Paracetamol",
    sizes: ["500mg", "250mg/5ml", "125mg/5ml"],
    size: "500mg",
    formulation: "oral",
    use: "painkiller",
    frequency: "tid",
    color: "#6666cc",
    dose: "Quarter tablet",
  },
  {
    name: "Trazodone",
    sizes: ["50mg", "100mg"],
    size: "50mg",
    formulation: "oral",
    use: "anti-anxiety",
    frequency: "tid",
    color: "#6666cc",
    dose: "1 tablet",
  },
  {
    name: "Gabapentin",
    formulation: "oral",
    use: "painkiller",
    frequency: "tid",
    color: "#6666cc",
    dose: "1 tablet",
  },
  {
    name: "Amodip",
    sizes: ["1.25mg"],
    size: "1.25mg",
    formulation: "oral",
    use: "blood pressure / neuroprotectant",
    frequency: "sid",
    color: "#6666cc",
    dose: "1 tablet",
  },
  {
    name: "Amlodipine",
    sizes: ["2.5mg", "5mg", "10mg", "1mg/ml"],
    size: "2.5mg",
    formulation: "oral",
    use: "blood pressure / neuroprotectant",
    frequency: "sid",
    color: "#6666cc",
    dose: "1 tablet",
  },
  {
    name: "Famciclovir",
    sizes: ["125mg", "250mg", "500mg"],
    size: "250mg",
    formulation: "oral",
    use: "antiviral",
    frequency: "bid",
    color: "#6666cc",
    dose: "1 tablet",
  },
  {
    name: "Famvir",
    sizes: ["125mg", "250mg", "500mg"],
    size: "250mg",
    formulation: "oral",
    use: "antiviral",
    frequency: "bid",
    color: "#6666cc",
    dose: "1 tablet",
  },
  {
    name: "Caninsulin",
    formulation: "injectable",
    use: "diabetes",
    frequency: "bid",
    color: "#1155bb",
    dose: "_ units",
  },
];

drugs.sort((a, b) => {
  if (a.name > b.name) return 1;
  if (a.name < b.name) return -1;
  return 0;
});

export default drugs;
